import {kycStore} from '@/Features/PublicApp/screens/onboarding/crm/KYCStore';
import {navigateAndReset} from '@/helpers/navigation';
import {accountStore, alertStore, appUiStore, queryClient} from '@/Lib';
import {documentStore} from '@/Lib/stores/DocumentStore';
import {sessionStore} from '@/Lib/stores/Session';
import {settingsStore} from '@/Lib/stores/SettingsStore';
import {runInAction} from 'mobx';

const getHistory = (variant: 'success' | 'failed' | 'welcome', link?: string) => ({
  md: [
    {
      name: 'Root',
      params: {
        screen: 'Profile',
        params: {
          screen: 'DepositWithCard',
        },
      },
    },
    {
      name: 'Root',
      params: {
        screen: 'Profile',
        params: {
          screen: 'ProfileWarningScreen',
          params: {variant, link},
        },
      },
    },
  ],
  mobile: [
    {
      name: 'Root',
      params: {screen: 'Profile'},
    },
    {name: 'PaymentMethod'},
    {
      name: 'ProfileWarningScreen',
      params: {variant, link},
    },
  ],
});

export const handlePushNotification = async (notification: any) => {
  const notificationData = notification?.data;
  const md = appUiStore.layout.width > 768;
  switch (notificationData?.cmd) {
    case 'uaepass_status_changed':
      runInAction(() => {
        settingsStore.gender = notificationData?.gender;
        settingsStore.dob = notificationData?.dob;
        settingsStore.datasharing = true;
      });
      navigateAndReset([
        {
          name: 'Root',
        },
        {name: 'UaepassDocSharingStatus', params: {variant: 'success'}},
      ]);
      break;
    case 'uaepass_failed':
      navigateAndReset([
        {
          name: 'Root',
        },
        {name: 'UaepassDocSharingStatus', params: {variant: 'failed'}},
      ]);
      break;
    case 'status_changed':
      await kycStore.fetch();
      await documentStore.fetch();

      const level1Approved = accountStore.AccountState?.name === 'LEVEL-1 Approved';
      if (level1Approved && accountStore.isMT) {
        settingsStore.setMtWelcome(false);
        navigateAndReset(getHistory('welcome', 'MTAccountInfo')[md ? 'md' : 'mobile']);
      }
      break;
    case 'payment_status_changed':
      if (!accountStore.TradingAccounts?.length) {
        await sessionStore.renewAccessToken();
        await accountStore.loadAccounts();
      }

      queryClient.refetchQueries(['notifications', sessionStore.account?.login]);
      queryClient.refetchQueries(['netdeposits']);
      queryClient.refetchQueries(['totaldeposits']);

      kycStore.fetchUserKYCDetails();
      accountStore.fetchAndUpdateMetrics();

      if (notification?.body) {
        appUiStore.setStatusModal({
          msg: notification.body,
        });
      }
      navigateAndReset([
        {name: 'Root', params: {screen: 'Profile'}},
        {name: 'StatusModal', params: {variant: 'success', name: 'deposit'}},
      ]);
      break;
    case 'deposit_failed':
      navigateAndReset([
        {name: 'Root', params: {screen: 'Profile'}},
        {name: 'StatusModal', params: {variant: 'failed', name: 'deposit'}},
      ]);
      break;
    case 'refresh_alerts':
      alertStore.setup();
      break;
    default:
      break;
  }
};
