import {SymbolInfo} from './entities/SymbolInfo';
import {sessionStore} from './Session';
import {waitFor} from '../helpers';
import {api} from '../api';
import DeviceEventEmitter from '../DeviceEmitter';
import {makeObservable, runInAction, observable} from 'mobx';
import type {MT} from '../types';

export class SymbolStore {
  data: Record<string, SymbolInfo> = {};
  indexes = {byRic: {}} as {byRic: Record<string, string>};
  ready = false;
  keys = new Set<string>();
  loadedEtag?: string;
  prices = new Map<string, MT.SymbolState>();
  hasCrypto = false;

  exists(symbol: string) {
    return !!this.data[symbol] || this.keys.has(symbol);
  }

  tryToToggleLeverage(ric: string, symbol: string) {
    const item = Object.values(this.data).find(s => s.data.Category === ric && s.symbol !== symbol);
    return {Symbol: item?.symbol};
  }

  clear() {
    this.data = {};
    this.indexes = {byRic: {}};
    this.ready = false;
    this.keys = new Set<string>();
  }

  resolveSymbol = (name: string | SymbolInfo) => {
    if (typeof name === 'string') {
      return this.getSymbolSync(name);
    }
    return name;
  };

  async init(group = 'default') {
    console.log('GETTING SYMBOLS');
    return api
      .get(`/api/symbols?type=csv`, {
        method: 'GET',
        headers: {
          vary: 'x-group',
          'x-group': group,
          Authorization: `Bearer ${await sessionStore.access_token}`,
        },
      })
      .then(r => r.data)
      .then(r => {
        let keys = [] as string[];

        this.clear();

        r.split('\n').forEach((s: string, idx: number) => {
          if (idx === 0) {
            // -- keys
            keys = s.split(',').map(n => JSON.parse(n)) as string[];
          } else {
            const values = s.split(',');
            const row = keys.reduce((acc, key, _idx: number) => {
              const val = values[_idx];
              if (val[0] === '[') {
                acc[key] = JSON.parse(val.replace(/-/g, ','));
              } else {
                acc[key] = values[_idx].replace(/"/g, '');
              }
              return acc;
            }, {} as any);
            const symbol = new SymbolInfo(row);
            this.data[row.Symbol] = symbol;
            this.indexes.byRic[row.Category] = row.Symbol;
            this.keys.add(row.Symbol);
          }
        });
        return this.data;
      })
      .then(() => {
        runInAction(() => {
          this.ready = true;
        });
      })
      .catch(e => {
        console.error('SYMBOL LOAD ERROR:', e);
        DeviceEventEmitter.emitEvent('LOGOUT', {reason: 'SYMBOL_CONFIG', error: e});
      });
  }

  constructor() {
    makeObservable(
      this,
      {
        keys: observable,
        ready: observable,
        prices: observable,
      },
      {deep: false}
    );
  }

  findByCurrencies(c1: string, c2: string, symbol = false) {
    let ucC1 = String(c1).toUpperCase();
    let ucC2 = String(c2).toUpperCase();
    const item = Object.values(this.data).find(
      s =>
        (s.data.CurrencyMargin === ucC1 && s.data.CurrencyProfit === ucC2) ||
        (s.data.CurrencyMargin === ucC2 && s.data.CurrencyProfit === ucC1)
    );

    if (symbol) {
      return item;
    }

    return item?.symbol;
    // const rate = symbol.ask;
    // return c1 === symbol.data.CurrencyProfit ? rate : 1 / rate;
  }

  async getSymbol(symbol: string) {
    if (typeof symbol !== 'string') return symbol;
    return waitFor(() => !!this.data[symbol], 100, 300000000)
      .then(() => {
        return this.data[symbol] || console.warn('tryed to get an unknown symbol ' + symbol);
      })
      .catch(() => {
        console.warn('tryed to get an unknown symbol ' + symbol);
      });
  }

  getSymbolSync(symbol: string): SymbolInfo | undefined {
    if (typeof symbol !== 'string' || !symbol) return undefined;
    if (!this?.data?.[symbol]) {
      // if (__DEV__)
      //   throw new Error(`SYMBOL NOT FOUND [${symbol}]` + !!symbol + '-' + symbol?.length);
      return undefined;
    }

    return this.data[symbol] || console.debug('tryed to get an unknown symbol ' + symbol);
  }

  getSymbolByRic(ric: string) {
    const symbol = this.resolveSymbol(this.indexes.byRic[ric]);
    if (!symbol) return null;
    return this.resolveSymbol(symbol);
  }
}

export const symbolStore = new SymbolStore();
