import {firebaseAuth} from '../otp.web';
import * as firebase from 'firebase/auth';
import {RecaptchaVerifier} from 'firebase/auth';

export class FirebaseAuth {
  providers = {
    facebook: () => new firebase.FacebookAuthProvider(),
    google: () => new firebase.GoogleAuthProvider(),
    apple: () => new firebase.OAuthProvider('apple.com'),
  };

  async verifyOTP(verificationCode: string, verificationId: string) {
    const phoneCredential = firebase.PhoneAuthProvider.credential(verificationId, verificationCode);
    const userCredential = await firebase.signInWithCredential(firebaseAuth, phoneCredential);

    return userCredential.user.getIdToken();
  }

  async otp(phoneNumber: string) {
    if (!window.recaptchaVerifier) {
      const container = document.createElement('div');
      container.id = 'recaptchaVerifier';
      document.body.appendChild(container);
      window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptchaVerifier', {
        size: 'invisible',
        callback: console.log,
      });
    } else {
      window.grecaptcha?.reset && window.grecaptcha.reset();
    }
    const provider = new firebase.PhoneAuthProvider(firebaseAuth);
    return await provider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier);
  }

  async signInWithProvider(type: keyof FirebaseAuth['providers']) {
    // Sign in using a popup.
    try {
      const provider = this.providers[type]();
      const result = await firebase.signInWithPopup(firebaseAuth, provider);

      // The signed-in user info.
      const user = result.user;

      // This gives you Access Token.
      return user.getIdToken();
    } catch (error) {
      console.log('signInWithProvider', error);
      return null;
    }
  }

  async signOut() {
    await firebase.signOut(firebaseAuth);
  }

  async register(email: string, password: string) {
    const userCredential = await firebase.createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    return userCredential.user;
  }

  async resetPassword(email: string) {
    await firebase.sendPasswordResetEmail(firebaseAuth, email);
  }

  onAuthStateChanged(cb: Parameters<typeof firebase.onAuthStateChanged>[1]) {
    return firebase.onAuthStateChanged(firebaseAuth, cb);
  }
}

export const firebaseAuthv2 = new FirebaseAuth();
