import en from './en.json';
import ar from './ar.json';
// import es from './es.json';
import {ILanguageCode} from '../const';
import config from '@/config';

const langFiles = {
  en,
  ar,
  // es,
};

export type LangTranslation = typeof en;

export const langCodes = config.languages.map(l => l as ILanguageCode);
export const Languages = Object.entries(langFiles).reduce((acc, cur) => {
  acc[cur[0]] = cur[1];
  return acc;
}, {} as {[key: string]: LangTranslation});
