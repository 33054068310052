export const STATES = [
  [
    'STARTED',
    'Started — the order correctness has been checked, and now the order is awaiting processing.',
  ],
  [
    'PLACED',
    'Placed — for pending orders, this state means that the order has been accepted and placed. For market orders, this state is only used when forwarding operations to an external system. In this case, this state means that the order has been forwarded and is awaiting execution in the external system.',
  ],
  ['CANCELED', 'Canceled — the order has been canceled by the client.'],
  [
    'PARTIAL',
    'Partially filled — the order has been partially filled, so the market operation has been executed for part of the initially requested volume.',
  ],
  ['FILLED', 'Filled — the order has been filled in full.'],
  ['REJECTED', 'Rejected — the order has been rejected by the broker.'],
  ['EXPIRED', 'Expired — the order has been canceled upon expiration.'],
  [
    'REQUEST_ADD',
    'The order passed (by the gateway) to be placed. This state is used for notifying that a request for placing the order is being already processed.',
  ],
  [
    'REQUEST_MODIFY',
    'The order passed (by the gateway) to be modified. This state is used for notifying that a request for modifying the order is being already processed.',
  ],
  [
    'REQUEST_CANCEL',
    'The order passed (by the gateway) to be deleted. This state is used for notifying that a request for deleting the order is being already processed.',
  ],
] as const;
