export const TYPES = [
  ['BUY', 'A Buy order.'],
  ['SELL', 'A Sell order.'],
  ['BUY_LIMIT', 'A Buy Limit order.'],
  ['SELL_LIMIT', 'A Sell Limit order.'],
  ['BUY_STOP', 'A Buy Stop order.'],
  ['SELL_STOP', 'A Sell Stop order.'],
  ['BUY_STLIMIT', 'A Buy Stop Limit .'],
  ['SELL_STLIMIT', 'A Sell Stop Limit order.'],
  [
    'CLOSE_BY',
    'A Close By order — a simultaneous closure of two opposite positions of the sane financial instrument. This operation type is only used in the hedging mode (EnMarginMode::MARGIN_MODE_RETAIL_HEDGED).',
  ],
] as const;

type CardType =
  | 'Discover'
  | 'Visa'
  | 'Mastercard'
  | 'American Express'
  | 'JCB'
  | 'Diners Club'
  | 'Maestro'
  | 'Mada';

export const cardSchemes: Record<string, CardType> = {
  Amex: 'American Express',
  'Diners Club': 'Diners Club',
  Discover: 'Discover',
  JCB: 'JCB',
  Mastercard: 'Mastercard',
  Visa: 'Visa',
  Mada: 'Mada',
  Maestro: 'Maestro',
} as const;

export type DocumentTypes =
  | 'tax_bill'
  | 'electricity_bill'
  | 'water_bill'
  | 'phone_bill'
  | 'bank_statement'
  | 'passport'
  | 'id_front'
  | 'id_back'
  | 'license';

export const MaxDayTrades = 3;
