import {AuthContent} from './AuthContent';
import {openPopup} from './utils/open-popup.web';
import {useT} from '@/Lib';
import {signInUaepass} from '@/Features/PublicApp/screens/lead-phase/LoginScreen/utils/sign-in-with-uaepass';
import {FirebaseAuth} from '@/firebase/v2/auth.web';
import {useAppLang} from '@amana/ui';
import {getAuth} from 'firebase/auth';
import {useState} from 'react';

const authService = new FirebaseAuth();
export function OAuth() {
  const [gauth] = useState(() => getAuth());
  const lang = useAppLang();
  const t = useT();
  gauth.languageCode = lang[0];

  return (
    <AuthContent
      onPressUAEPass={() =>
        openPopup().then(token =>
          signInUaepass(token, () => null, t.social.signInFailed).catch(console.warn)
        )
      }
      onPressApple={() => {
        authService.signInWithProvider('apple');
      }}
      onPressGoogle={() => {
        authService.signInWithProvider('google');
      }}
      onPressFacebook={() => authService.signInWithProvider('facebook')}
    />
  );
}
