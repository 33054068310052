import {appUiStore} from './AppUi';
import {accountStore} from './AccountStore';
import {positionStore} from './PositionStore';
import {ordersStore} from './OrderStore';
import {watchlistStore} from './WatchListStore';
import {sessionStore} from './Session';
import {settingsStore} from './SettingsStore';
import {passStorage} from '../../Features/PublicApp/screens/lead-phase/LoginScreen/LoginScreen';
import {storage} from '../storage';
import {queryClient} from '../queries';
import DeviceEventEmitter from '../DeviceEmitter';
import {symbolStore} from '@/Lib';
import {firebaseAuthv2} from '@/firebase/v2/auth';
import {MARKETING_URL} from '@/Env/index.web';
import {runInAction} from 'mobx';
import {clear} from 'suspend-react';
import {Platform} from 'react-native';

DeviceEventEmitter.on('LOGOUT', params => {
  console.warn('LOGOUT EVENT RECEIVED, LOGGING OUT');
  logout(params?.keepStorage);
});

export function logout(keepStorage: boolean | null = false) {
  if (Platform.OS === 'web') {
    // localStorage.clear();
    sessionStorage.clear();
  }
  runInAction(async () => {
    await firebaseAuthv2.signOut();
    await sessionStore.logout();

    if (keepStorage === false) {
      console.log('CLEARING STORAGE');
      passStorage.delete('LAST_PASSWORD');
      symbolStore.symbolDb?.clear();
      storage.clearAll();
    }
    accountStore.clear();
    positionStore.clear();
    ordersStore.clear();
    appUiStore.clear();
    watchlistStore.clear();
    queryClient.clear();
    symbolStore.clear();
    clear();
  });
  if (Platform.OS === 'web') {
    // localStorage.clear(); don't clear entire local storage only remove some keys.
    storage.delete('PREVIOUS_SESSION_TOKEN');
    sessionStorage.clear();
    if (!__DEV__)
      document.location.href = `${MARKETING_URL}/webtrader?event=logout&lang=${settingsStore.lang}`;
  }
}

// export default new RootStore();
