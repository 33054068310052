import DeviceEventEmitter from '../DeviceEmitter';
import Base64 from '@/components/Charts/lib/base64';
import {crmApi} from '@/Lib/api/crmApi';
import {storage} from '@/Lib/storage';
import {makeAutoObservable} from 'mobx';

export class Session {
  static refresh_request: Promise<I['tokens'] | undefined> | null = null;
  private token: string | undefined = undefined;
  private decodedToken: I['Jwt'] | undefined = undefined;
  private _refresh_token: string | undefined = undefined;

  get refresh_token() {
    return this._refresh_token;
  }
  get access_token() {
    this.token;
    return this.getorRefreshAccessToken();
  }

  get isExpired() {
    return this.ttl < 500; // it will expire in less than 500ms
  }

  get needRefresh() {
    return this.ttl < 60000; // it will expire in less than 1 minute
  }

  get ttl() {
    if (!this.decodedToken) return 0;
    return this.decodedToken?.exp * 1000 - Date.now();
  }

  get exp() {
    if (!this.decodedToken) return 0;
    return new Date(this.decodedToken?.exp * 1000);
  }

  get jwt() {
    return this.decodedToken;
  }

  get email() {
    return this.decodedToken?.email || '';
  }

  get cuid() {
    return this.decodedToken?.sub || '';
  }

  get account() {
    return this.decodedToken?.mt?.[0];
  }

  get isLogged() {
    return !!this.token;
  }

  async getorRefreshAccessToken(): Promise<string | null> {
    try {
      console.debug('Get or Refresh token..', this.ttl);
      if (!this.token) {
        // No current token available
        return null;
      }

      if (!this.isExpired) {
        console.debug('Token is not expired..');
        // Token is not expired
        if (this.needRefresh) {
          // Token needs refresh but is not yet expired
          console.debug('Token is almost dead.. renewing..');
          await this.renewAccessToken();
        }
        return this.token;
      }
      // At this point, the token is expired
      if (!this._refresh_token) {
        // No refresh token available to renew the access token
        return null;
      }

      // Try to renew the access token with the refresh token
      console.debug('Token is dead.. renewing..');
      await this.renewAccessToken();
      console.log('new token:', this.needRefresh);
      return this.token; // Might still be null if renewAccessToken doesn't set a new token
    } catch (error) {
      storage.delete('refresh_token');
      // Log the error or handle it as needed
      console.error('Error getting access token:', error);
      return null; // Return null in case of any errors
    }
  }

  async renewAccessToken() {
    console.log('renew access token.. current session is:', this._refresh_token);
    const newTokens = await this.fetch_refreshToken(this._refresh_token);
    if (!newTokens) {
      console.log('NO NEW TOKEN');
      DeviceEventEmitter.emitEvent('LOGOUT', {reason: '401'});
      return;
    }
    this.onRefresh(newTokens);
  }

  async login(email: string, password: string) {
    const tokens = await crmApi.auth.login({email, password});
    this.onRefresh(tokens.data);
  }

  async logout() {
    Session.refresh_request = null;
    this.token = undefined;
    this._refresh_token = undefined;
    this.decodedToken = undefined;
    this.onTokenChange();
  }

  fetch_refreshToken(code?: string) {
    Session.refresh_request =
      Session.refresh_request ||
      crmApi.auth
        .refreshToken({refresh_token: code})
        .then(r => r.data)
        .finally(() => {
          Session.refresh_request = null;
        });

    return Session.refresh_request;
  }

  socialLogin = (token: string) => {
    return crmApi.auth.socialSignup({token}).then(r => {
      this.onRefresh(r.data);
      return r.data;
    });
  };

  constructor(tokens?: I['tokens']) {
    if (tokens?.access_token && tokens?.refresh_token) this.onLogin(tokens);

    // ttl and exp use Date. So, we don't want to make them observable or they will be cached
    makeAutoObservable(this, {
      ttl: false,
      exp: false,
      isExpired: false,
      needRefresh: false,
      jwt: false,
    });
  }

  onTokenChange() {
    if (this.token) {
      storage.set('PREVIOUS_SESSION_TOKEN', this.token);
    } else {
      storage.delete('PREVIOUS_SESSION_TOKEN');
    }

    if (this._refresh_token) {
      storage.set('refresh_token', this._refresh_token);
    } else {
      storage.delete('refresh_token');
    }
  }

  onLogin(tokens: I['tokens']) {
    this.decodedToken = decodeToken(tokens.access_token);
    this.token = tokens.access_token;
    this._refresh_token = tokens.refresh_token;
  }

  onRefresh(tokens: I['tokens']) {
    this.decodedToken = decodeToken(tokens.access_token);
    this.token = tokens.access_token;
    this._refresh_token = tokens.refresh_token;
    this.onTokenChange();
  }

  onLogout(forget: boolean = false) {
    this.token = undefined;
    if (forget) this._refresh_token = undefined;
    this.decodedToken = undefined;
  }
}

const previousToken = storage.getString('PREVIOUS_SESSION_TOKEN');
const previousRefresh = storage.getString('refresh_token');
export const sessionStore = new Session({
  access_token: previousToken!,
  refresh_token: previousRefresh!,
});

interface I {
  tokens: {
    refresh_token: string;
    access_token: string;
  };

  Jwt: JwtToken;
}

function decodeToken(t?: string) {
  if (!t || typeof t !== 'string') {
    console.log(t);
    throw new Error('no token');
  }
  const [_head, body, _tail] = t.split('.');
  const json = Base64.atob(body);
  const decoded = JSON.parse(json) as I['Jwt'];
  if (!decoded || !decoded.sub) throw new Error('no decoded');

  return decoded;
}

interface JwtToken {
  email: string;
  scope: string;
  sub: string;
  active: string;
  exp: number;
  iat: number;
  id: number;
  mt: {
    id: string;
    group: string;
    login: string;
    dwid: string;
  }[];
  role: number;
}
