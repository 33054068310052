import {firebaseApp} from './index.web';
import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  signOut as DefaultSignOut,
} from 'firebase/auth';
import {FirebaseError} from 'firebase/app';

export const firebaseAuth = getAuth(firebaseApp);

if (__DEV__) {
  firebaseAuth.settings.appVerificationDisabledForTesting = __DEV__;
}

class PhotAuthStore {
  canRequestVerificationCode = true;
  verificationCodeDelay!: NodeJS.Timeout;
  verificationId = '';
  startVerificationCodeDelay() {
    this.canRequestVerificationCode = false;
    this.verificationCodeDelay = setTimeout(() => {
      this.canRequestVerificationCode = true;
    }, 30 * 1000);
  }

  resetVerificationCodeDelay() {
    clearTimeout(this.verificationCodeDelay);
    this.canRequestVerificationCode = true;
  }

  async signInWithPhoneNumber(phoneNumber: string, recaptchaVerifier: RecaptchaVerifier) {
    console.log('SIGNIN WITH PHONE NUMBER', phoneNumber);
    try {
      const confirmationResult = await firebaseSignInWithPhoneNumber(
        firebaseAuth,
        phoneNumber,
        recaptchaVerifier
      );
      this.verificationId = confirmationResult.verificationId;
      return confirmationResult.verificationId;
    } catch (error: unknown) {
      const e = error as FirebaseError;
      throw e?.code || 'unknown';
    }
  }
}

let phoneAuthStore = new PhotAuthStore();

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier;
    grecaptcha: any;
  }
}

export function signOut() {
  return DefaultSignOut(firebaseAuth).catch(() => null);
}

export function sendOTP(phoneNumber: string, lang: string) {
  // @ts-ignore
  if (!window.recaptchaVerifier) {
    const container = document.createElement('div');
    container.id = 'recaptchaVerifier';
    document.body.appendChild(container);
    window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptchaVerifier', {
      size: 'invisible',
      callback: console.log,
    });
  } else {
    // @ts-ignore
    window.grecaptcha?.reset && window.grecaptcha.reset();
  }

  phoneAuthStore.verificationId = '';
  firebaseAuth.languageCode = lang || 'en';
  return phoneAuthStore.signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier);
}

export function verifyOTP(code: string) {
  const credential = PhoneAuthProvider.credential(phoneAuthStore.verificationId, code);
  // --- reset recaptcha
  return signInWithCredential(firebaseAuth, credential)
    .then(r => {
      return r.user.getIdToken();
    })
    .catch(e => {
      console.log('error code is', e.code);
      throw e?.code || 'unknown';
    });
}
