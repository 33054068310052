import {accountStore} from './stores';
import {sessionStore} from './stores/Session';
import {lazy} from 'react';
import {initialWindowMetrics} from 'react-native-safe-area-context';
import isFinite from 'lodash/isFinite';
import type {Currencies, Strapi, TradingAccount} from './types';

export function tryParseJson(n: string) {
  try {
    if (typeof n !== 'string' || (n[0] !== '{' && n[0] !== '[')) {
      return String(n);
    }

    return JSON.parse(n);
  } catch (e) {
    return String(n);
  }
}

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

export async function waitFor(
  condition: boolean | (() => boolean),
  sleepms = 100,
  timeout?: number
) {
  let timeouted = false;
  if (timeout) {
    setTimeout(() => {
      timeouted = true;
    }, timeout);
  }

  while ((typeof condition === 'function' ? condition() : condition) === false && !timeouted) {
    await sleep(sleepms);
  }

  return (typeof condition === 'function' ? condition() : condition) || null;
}

export function RoundByStep(num: number, step: number, ciel = false) {
  // null;
  if (!step) {
    return num;
  }
  const fn = ciel ? Math.ceil : Math.floor;
  const precision = Number(step).toFixed(10).replace(/0*$/, '').split('.').pop()?.length || 0;
  const result = (step * fn(num / step)).toFixed(precision);
  return result.endsWith('9.99') ? Math.ceil(Number(result)) : Number(result);
}

export function floatAdd(a: number | string, b: number | string) {
  const precision = `${a}`.split('.').pop()?.length || 0;
  return Number((Number(a) + Number(b)).toFixed(precision));
}

export function floatSubtract(a: number | string, b: number | string) {
  const precision = Math.max(
    `${a}`.split('.').pop()?.length || 0,
    `${b}`.split('.').pop()?.length || 0
  );
  return Number((Number(a) - Number(b)).toFixed(precision));
}

export function debounce<T extends unknown[]>(
  func: (...args: T) => void,
  delay: number
): (...args: T) => void {
  let timer: number | any | null = null;
  return (...args: T) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.call(null, ...args);
    }, delay);
  };
}

export const SuperLazy: typeof lazy = componentImport =>
  lazy(async () => {
    try {
      const component = await componentImport().catch(() =>
        sleep(1000).then(() => componentImport())
      );
      return component;
    } catch (error) {
      throw error;
    }
  });

export function SuspendPromise<T = any>(promise: Promise<T>) {
  let status: 'pending' | 'success' | 'error' = 'pending';
  let response: T | null = null;

  const suspender = promise.then(
    res => {
      status = 'success';
      response = res;
    },
    err => {
      status = 'error';
      response = err;
    }
  );

  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender;
      case 'error':
        throw response;
      default:
        return response;
    }
  };

  return {read};
}

function formatDatetime(
  lang: string | string[] | undefined,
  opts: Intl.DateTimeFormatOptions | undefined = {},
  date: string | number | Date
) {
  try {
    const locale = lang === 'ar' ? `ar-EG` : 'en-US';
    if (typeof window !== 'undefined' && 'hour' in opts) {
      delete opts?.timeStyle;
    }
    return new Intl.DateTimeFormat(locale, opts).format(new Date(date));
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function printDate(
  date: string | number | Date,
  lang: string | string[] | undefined,
  opts: Intl.DateTimeFormatOptions | undefined = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
) {
  return formatDatetime(lang, opts, date);
}

export function printTime(
  date: string | number | Date,
  lang: string | string[] | undefined,
  opts: Intl.DateTimeFormatOptions | undefined = {
    // hour: '2-digit',
    // minute: '2-digit',
    timeStyle: 'short',
    hour12: true,
  }
) {
  return formatDatetime(lang, opts, date).replace(' ', '') || '--:--';
}

export function safePrintFloat(num: number, digits = 2) {
  try {
    return Number(num).toFixed(digits);
  } catch (error) {
    return '-';
  }
}

export function safePrintCurrency(num: number, currency = 'USD', digits = 2, locale = 'en-US') {
  try {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      style: 'currency',
      currency,
    }).format(Number(num));
  } catch (error) {
    return safePrintFloat(num, digits);
  }
}

export function printNum(_: string, num: string) {
  // const locale = lang === 'ar' ? `ar-EG` : 'en-US';
  const locale = 'en-US';
  const n = Number(num);
  if (isFinite(n) === false) {
    return '-';
  }
  return new Intl.NumberFormat(locale).format(Number(num));
}

const minmax = function minmax(array: number[]) {
  if (!is(Array, array)) {
    return {min: undefined, max: undefined};
  }

  const min = Math.min(...array);
  const max = Math.max(...array);

  return {min, max};
};

const exists = (thing: any) => {
  return !(thing === undefined || thing === null || Number.isNaN(thing));
};

const is = (Type: any, thing: any) => {
  return exists(Type) && exists(thing) && (thing.constructor === Type || thing instanceof Type);
};

export function calculateGraphPoints(values = [] as number[], width = 200, height = 80) {
  let points: string[] = [];
  let smooth = values.length > 500;
  const valuesLen = smooth ? Math.floor(values.length / 2) : values.length;
  const offsetX = width / valuesLen;
  let cx, cy;

  let {min = 0, max = 0} = minmax(values);
  let diff = max - min;
  let x = offsetX;

  if (max === 0 && min === 0) {
    max = 1;
    diff = 2;
  }

  if (diff === 0) {
    if (max > 0) {
      min = 0;
      max *= 2;
    } else {
      min = min + max;
      max = 0;
    }
    diff = max - min;
  }

  let prev = 0;
  values.forEach((value, idx) => {
    if (smooth && idx % 2 === 0) return;
    const y = ((max - value) / diff) * height;
    const ry = Math.round(y);
    if (prev !== ry) {
      points.push(`${x},${y}`);
    }
    x += offsetX;
    prev = ry;
  });

  if (points.length < 2) return null;
  [cx, cy] = points[points.length - 1].split(',');
  return {points, cx, cy};
}

export function cdn(url: string) {
  const cdnLink = String(url)
    .replace(
      'https://amana-strapy.s3.eu-west-2.amazonaws.com/',
      'https://d17h1wxepw3rgo.cloudfront.net/'
    )
    .replace('https://amana-strapy.s3.amazonaws.com/', 'https://d17h1wxepw3rgo.cloudfront.net/');
  // const ret = Platform.select({ios: cdnLink, android: url}); // disable on android till we solve cert warning
  return cdnLink;
}

export function strapimediaURI(attributes: Strapi.Media['data']['attributes'], factor = 0.5) {
  const width = (initialWindowMetrics?.frame.width || 400) * factor;
  let attr =
    width < attributes?.formats?.thumbnail.width ? attributes?.formats?.thumbnail : undefined;
  if (!attr && width < attributes?.formats?.small?.width) {
    attr = attributes?.formats?.small;
  }
  if (!attr && width < attributes?.formats.medium?.width) {
    attr = attributes?.formats.medium;
  }
  return cdn(attr ? attr?.url || attributes?.url : attributes?.url);
}

export function getDepositRealAmount(value: number) {
  let res = 0;
  const val = String(value);
  if (val.includes('.')) {
    const [int, dec] = val.split('.');
    res = +(+int * 100 + '.' + dec);
  } else res = value * 100;
  return res;
}

export function testId(testID: string) {
  return testID;
  // const appIdentifier = getBundleId();

  // if (!testID) {
  //   return undefined;
  // }

  // const prefix = `${appIdentifier}:id/`;
  // const hasPrefix = testID.startsWith(prefix);

  // return Platform.select({
  //   android: !hasPrefix ? `${prefix}${testID}` : testID,
  //   ios: hasPrefix ? testID.slice(prefix.length) : testID,
  //   web: testID,
  // });
}

// setTrottledTimeout set a timeout that will run after NN ms
export function setTrottledTimeout(cb: () => void, waitTime = 500) {
  let i = setTimeout(cb, waitTime);
  return () => clearTimeout(i);
}

export function floorToMultiple(num: number, multiple: number) {
  return Math.floor(num / multiple) * multiple;
}

export function cielToMultiple(num: number, multiple: number) {
  return Math.ceil(num / multiple) * multiple;
}

export function isEmail(txt: string) {
  return /[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(txt);
}

export function abbreviateNumber(number: number) {
  var SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];

  var tier = Math.log10(Math.abs(Number(number))) / 3 || 0;

  if (tier === 0) return number;

  var suffix = SI_SYMBOL[tier];
  if (!suffix) return number;
  var scale = Math.pow(10, tier * 3);

  var scaled = number / scale;

  return scaled.toFixed(1) + suffix;
}

export function rateToUsd(currency: Currencies) {
  if (currency === 'USD') return 1;
  else return 3.67;
}
export function calcUSDAmount(amount: number, currency: Currencies) {
  const rate = rateToUsd(currency);
  return amount / rate;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function formatAmount(amount: any, cutSI = 0): {amount: string; suffix: string} {
  const number = Number(amount);
  if (Number.isNaN(number)) return {amount: '', suffix: ''};

  const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'P'].slice(cutSI);

  const tier = Math.floor(Math.log10(Math.abs(Number(number))) / 3) || 0;

  const suffix = SI_SYMBOL[tier];
  if (tier === 0 || !suffix) return {amount: String(number), suffix: SI_SYMBOL[0]};

  const scale = Math.pow(10, tier * 3);

  const scaled = number / scale;

  return {amount: scaled.toFixed(2), suffix};
}

export function checkIfMT(account: TradingAccount): boolean {
  return !!account?.type && account?.type !== 'MOBILE';
}

export function isActiveAccountMT() {
  const activeAccount = accountStore.TradingAccounts?.find(t => t.id === sessionStore.account?.id);
  if (!activeAccount) return false;
  return checkIfMT(activeAccount);
}

export function getQueryParams(url: string) {
  // Use the URL constructor to parse the URL.
  const parsedUrl = new URL(url);

  // Use the searchParams API to iterate over each query parameter.
  const params = {} as Record<string, string>;
  for (let [key, value] of parsedUrl.searchParams.entries()) {
    params[key] = value;
  }

  return params;
}
