import {tickStore} from './Prices';
import DeviceEventEmitter from './DeviceEmitter';
import {inAppNotificationsStore} from './stores/InAppNotificationsStore';
import {sessionStore} from './stores/Session';
import {appUiStore, isJsonString, sleep} from '@/Lib';
import {isProduction, PRICE_WS} from '@/Env';
import {encode, decode} from 'msgpack-lite';
import {AppState} from 'react-native';
import {autorun} from 'mobx';

type ConnectionStatus =
  | {shouldConnect: true; token: string}
  | {shouldConnect: false; reason: string};

export class PriceEngine {
  isReconnecting = false;
  socket?: WebSocket;
  subscriptions = new Map<string, boolean>();
  pongTimeout: NodeJS.Timeout | undefined; // how long we wait for pong;
  token = '';

  async shouldBeConnected(): Promise<ConnectionStatus> {
    const appIsFocused = AppState.currentState === 'active';
    if (!appIsFocused) {
      return {shouldConnect: false, reason: 'App is not in focus'};
    }

    const token = await sessionStore.getorRefreshAccessToken();
    if (!token) {
      return {shouldConnect: false, reason: 'Not Logged in'};
    }

    return {shouldConnect: true, token};
  }

  dispose() {
    clearTimeout(this.pongTimeout);
    if (!this.socket) return;
    // clear all listeners to prevent reconnect
    this.socket.onopen = null;
    this.socket.onmessage = null;
    this.socket.onclose = null;
    this.socket.onerror = null;
    this.socket.close();
    this.socket = undefined;
  }
  setup = async () => {
    this.isReconnecting = false; // this prop is used to prevent multiple reconnection

    const ok = await this.shouldBeConnected();
    if (!ok.shouldConnect) {
      return console.log('PE: not connecting', ok.reason);
    }

    // at this point. its okay to connect;
    // question now is, are we already connected?
    if (this.socket?.readyState === WebSocket.CONNECTING && this.token === ok.token) {
      return console.log('PE: already connected');
    }

    console.log('PE: connecting ');
    this.dispose();
    this.token = ok.token;

    const socket = new WebSocket(PRICE_WS);
    socket.binaryType = 'arraybuffer';
    socket.onmessage = e => this.onmessage(e);
    socket.onopen = () => this.onOpen();
    socket.onclose = () => this.reconnect('onclose');
    socket.onerror = e => this.onError(e);
    this.socket = socket;
  };

  reconnect(...params: any): void {
    console.log('PE: reconnecting', params, this.isReconnecting, this.socket?.readyState);
    this.isReconnecting = true;
    sleep(1000).then(this.setup);
  }

  onOpen = async () => {
    const token = await sessionStore.getorRefreshAccessToken();
    if (token) {
      this.token = token;
      this.send('login', [token, isProduction]);
    } else {
      console.error("PE: Can't connect without token");
      DeviceEventEmitter.emit('LOGOUT');
    }
  };

  onError(e: Event) {
    console.error('PE ERROR: ', e);
    this.reconnect('ERROR', e);
  }

  send = async (type: string, payload: unknown[]) => {
    // const isConnected = this.socket?.readyState !== WebSocket.OPEN && this.ready;
    if (this.isConnected()) {
      let n = encode({
        type,
        payload,
      });
      this.socket?.send(n);
      // console.log("PE: command: '", type, payload, this.socket);
    } else {
      console.log('PE: ERROR THIS command WILL BE SKIPPED', type, payload);
    }
  };

  unsubscribe(symbol: string) {
    if (this?.subscriptions.has(symbol)) {
      const removed = this.subscriptions.delete(symbol);
      removed && this.send('unsubscribe', [symbol]);
    }
  }

  subscribe = (name: string) => {
    this.subscriptions.set(name, true);
    this.send('subscribe', [name]);
  };

  _handleMTEvents = (payload: any) => {
    const target = payload.Position || payload.PositionID ? 0 : 1;
    if ('Deal' in payload && 'Profit' in payload) {
      // --- on profitable trade
      appUiStore.fetchAndUpdateMTData();
      return true;
    }

    if ('Hook' in payload) {
      // --- DELETED A POSITION
      if (payload.Hook === 'DEL' && payload.Position) {
        DeviceEventEmitter.emit('navigate', [
          'OrderSuccess',
          {
            msg: 'MT_RET_REQUEST_ACCEPTED',
            target,
          },
        ]);
        return true;
      }

      if (payload.State) {
        const n = TRADE_RESPONSE_CODES[payload.State];
        if (n && n[1] !== null) {
          DeviceEventEmitter.emit(n[1] ? 'OrderSuccess' : 'OrderFailed', {
            msg: (n[1] ? 'MT_RET_REQUEST_ACCEPTED' : String(n[0])) || undefined,
            target,
          });
          return true;
        }
      }

      if (payload.State === 5) {
        DeviceEventEmitter.emit('navigate', [
          'OrderFailed',
          {
            msg: 'MT_RET_REQUEST_REJECT',
            target,
          },
        ]);
        return true;
      }
      if ('Position' in payload && payload.Hook === 'UP') {
        DeviceEventEmitter.emit('navigate', [
          'OrderSuccess',
          {
            msg: 'MT_RET_REQUEST_ACCEPTED',
            target,
          },
        ]);

        DeviceEventEmitter.emit('positions', {type: 'update'});
        return true;
      }
      if (payload.Hook === 'DEL') {
        if ('Position' in payload || 'PositionID' in payload) {
          DeviceEventEmitter.emit('positions', {type: 'remove', id: payload.PositionID});
        }

        if ('Order' in payload) {
          DeviceEventEmitter.emit('orders', {type: 'remove', id: payload.Order});
        }

        return true;
      }

      // ---- LIMIT ORDER PLACED
      if (payload.Hook === 'UP' && payload.State === 1 && payload.PositionID === 0) {
        DeviceEventEmitter.emit('navigate', [
          'OrderSuccess',
          {
            msg: 'MT_RET_REQUEST_ACCEPTED',
            target,
          },
        ]);
      }
      appUiStore.fetchAndUpdateMTData();
      return true;
    }

    return false;
  };

  onBinaryMsg = (data: ArrayBuffer) => {
    const raw_binary_data = new Uint8Array(data);
    const message = decode(raw_binary_data);

    if (typeof message === 'object' && 'Ask' in message) {
      const t = message;
      return tickStore.onTick(t.Symbol, {
        Symbol: t.Symbol,
        Bid: t.Bid,
        Ask: t.Ask,
        dayHigh: t.dayHigh,
        dayLow: t.dayLow,
        ltp: t.ltp,
        Datetime: t.Datetime,
        close: t.close,
        open: t.open,
      });
    }
    if (Array.isArray(message)) {
      // @DEPRICATED: graph data now come as pngs
      // if (message[0] === 'graph') {
      //   const [, name, rawTicks] = message;
      //   const ticks = JSON.parse(`[${rawTicks}]`) as number[];
      //   lastNbids.set(name, ticks);
      //   return;
      // }

      // -- a market price
      // if (Number(1) === 1) return;
      const [symbol, tsms, bid, ask, last] = message;
      tickStore?.onTick(symbol, {
        Datetime: tsms,
        Ask: ask,
        Bid: bid,
        ltp: last,
      });
      return;
    }
    let handled = false;
    if ('Hook' in message || 'Deal' in message || 'Profit' in message) {
      handled = this._handleMTEvents(message);
    }
    handled || console.warn('UNKNOWN', message);
  };

  onJsonMsg = async (data: string) => {
    const d = JSON.parse(data);
    if (d?.type === 'push_notification') {
      inAppNotificationsStore.handleWebsocketNotification(d);
    }
  };

  onmessage = ({data}: any) => {
    clearTimeout(this.pongTimeout);
    this.pongTimeout = setTimeout(() => {
      console.log('PE: socket did not get any message in last 10s, reconnecting...');
      this.reconnect('PONG');
    }, 9000);

    if (data instanceof ArrayBuffer) {
      this.onBinaryMsg(data);
      return;
    }

    if (data === '200') {
      console.log('CONNECTED..', JSON.stringify(this.subscriptions));
      Array.from(this.subscriptions.keys()).map(n => this.subscribe(n));
    }

    if (data === '403') {
      this.dispose();
      this.reconnect('403');
    }

    if (data === 'pong') {
      return;
    }

    if (`${data}`.startsWith('unsubscribe')) {
      return;
    }

    if (isJsonString(data)) {
      this.onJsonMsg(data);
      return;
    }

    console.warn('UNKOWN MSG', data);
  };

  isConnected() {
    return this.socket?.readyState === WebSocket.OPEN;
  }
}

export const TRADE_RESPONSE_CODES = [
  ['MT_RET_REQUEST_INWAY', null],
  ['MT_RET_REQUEST_ACCEPTED', null],
  ['MT_RET_REQUEST_PROCESS', null],
  ['MT_RET_REQUEST_REQUOTE', null],
  ['MT_RET_REQUEST_PRICES', null],
  ['MT_RET_REQUEST_REJECT', false],
  ['MT_RET_REQUEST_CANCEL', false],
  ['MT_RET_REQUEST_PLACED', null],
  ['MT_RET_REQUEST_DONE', true],
  ['MT_RET_REQUEST_DONE_PARTIAL', true],
  ['MT_RET_REQUEST_ERROR', false],
  ['MT_RET_REQUEST_TIMEOUT', false],
  ['MT_RET_REQUEST_INVALID', false],
  ['MT_RET_REQUEST_INVALID_VOLUME', false],
  ['MT_RET_REQUEST_INVALID_PRICE', false],
  ['MT_RET_REQUEST_INVALID_STOPS', false],
  ['MT_RET_REQUEST_TRADE_DISABLED', false],
  ['MT_RET_REQUEST_MARKET_CLOSED', false],
  ['MT_RET_REQUEST_NO_MONEY', false],
  ['MT_RET_REQUEST_PRICE_CHANGED', false],
  ['MT_RET_REQUEST_PRICE_OFF', false],
  ['MT_RET_REQUEST_INVALID_EXP', false],
  ['MT_RET_REQUEST_ORDER_CHANGED', true],
  ['MT_RET_REQUEST_TOO_MANY', false],
  ['MT_RET_REQUEST_NO_CHANGES', null],
  ['MT_RET_REQUEST_AT_DISABLED_SERVER', false],
  ['MT_RET_REQUEST_AT_DISABLED_CLIENT', false],
  ['MT_RET_REQUEST_LOCKED', false],
  ['MT_RET_REQUEST_FROZEN', false],
  ['MT_RET_REQUEST_INVALID_FILL', false],
  ['MT_RET_REQUEST_CONNECTION', false],
  ['MT_RET_REQUEST_ONLY_REAL', false],
  ['MT_RET_REQUEST_LIMIT_ORDERS', false],
  ['MT_RET_REQUEST_LIMIT_VOLUME', false],
  ['MT_RET_REQUEST_INVALID_ORDER', false],
  ['MT_RET_REQUEST_POSITION_CLOSED', false],
  ['MT_RET_REQUEST_EXECUTION_SKIPPED', false],
  ['MT_RET_REQUEST_INVALID_CLOSE_VOLUME', false],
  ['MT_RET_REQUEST_CLOSE_ORDER_EXIST', false],
  ['MT_RET_REQUEST_LIMIT_POSITIONS', false],
  ['MT_RET_REQUEST_REJECT_CANCEL', false],
  ['MT_RET_REQUEST_LONG_ONLY', false],
  ['MT_RET_REQUEST_SHORT_ONLY', false],
  ['MT_RET_REQUEST_CLOSE_ONLY', false],
  ['MT_RET_REQUEST_PROHIBITED_BY_FIFO', false],
  ['MT_RET_REQUEST_HEDGE_PROHIBITED', false],
];

export const priceEngine = new PriceEngine();
autorun(async () => {
  const token = await sessionStore.access_token;
  if (token) {
    priceEngine.setup();
  } else {
    priceEngine.dispose();
  }
});
AppState.addEventListener('change', change => {
  if (change === 'active') {
    priceEngine.setup();
  } else if (change === 'background') {
    priceEngine.dispose();
  }
});
