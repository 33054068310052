export const USER_STATUSES = {
  ACTIVE: {
    title: 'Approved',
    name: 'ACTIVE',
  },
  CLOSED: {
    title: 'Closed',
    name: 'CLOSED',
  },
  COMPLIANCEREVIEW: {
    title: 'Compliance Review',
    name: 'COMPLIANCEREVIEW',
  },
  DEMO: {
    title: 'Demo Registration',
    name: 'DEMO',
  },
  DISABLED: {
    title: 'Disabled',
    name: 'DISABLED',
  },
  DORMANT: {
    title: 'Dormant Account',
    name: 'DORMANT',
  },
  'DORMANT AGAIN': {
    title: 'Archived',
    name: 'DORMANT AGAIN',
  },
  DPIPSPENDING: {
    title: 'DPIPs -registration pending',
    name: 'DPIPSPENDING',
  },
  FAILSCORE: {
    title: 'Knowledge Test Failed',
    name: 'FAILSCORE',
  },
  FATCAUS: {
    title: 'FATCA-Disabled',
    name: 'FATCAUS',
  },
  FPPOSPENDING: {
    title: 'FPPO’s -registration pending',
    name: 'FPPOSPENDING',
  },
  INACTIVE: {
    title: 'Inactive',
    name: 'INACTIVE',
  },
  INCOMPLETE: {
    title: 'Incomplete Steps',
    name: 'INCOMPLETE',
  },
  INCOMPLETEKYC: {
    title: 'Incomplete KYC',
    name: 'INCOMPLETEKYC',
  },
  'LEVEL-1 Approved': {
    title: 'LEVEL-1 Approved',
    name: 'LEVEL-1 Approved',
  },
  NEW: {
    title: 'Registered',
    name: 'NEW',
  },
  NODOCS: {
    title: 'Missing Documents',
    name: 'NODOCS',
  },
  PENDING: {
    title: 'Pending KYC',
    name: 'PENDING',
  },
  'Pending IDWISE': {
    title: 'Pending IDWISE',
    name: 'Pending IDWISE',
  },
  PENDINGKYCREVIEW: {
    title: 'Pending KYC Review',
    name: 'PENDINGKYCREVIEW',
  },
  PEPPENDING: {
    title: 'Pending Promotion',
    name: 'PEPPENDING',
  },
  REJECTED: {
    title: 'Rejected',
    name: 'REJECTED',
  },
  'UAEP Incomplete Steps': {
    title: 'UAEP Incomplete Steps',
    name: 'UAEP Incomplete Steps',
  },
  'UAEP Pending ID': {
    title: 'UAEP Pending ID',
    name: 'UAEP Pending ID',
  },
  'UAEP Pending PDF': {
    title: 'UAEP Pending PDF',
    name: 'UAEP Pending PDF',
  },
} as const;

export type UserStatus = (typeof USER_STATUSES)[keyof typeof USER_STATUSES];
export type StatusNames = UserStatus['title'];
