import {Api} from './crm/Api';
import {DIST, CRM_HTTP} from '@/Env';
import {Platform} from 'react-native';

export const crmApi = new Api({
  baseURL: CRM_HTTP,
  headers: {
    'x-version': DIST,
    'Content-Type': 'application/json',
    accept: 'application/json',
    'x-platfrom': Platform.OS !== 'web' ? Platform.OS + '-' + Platform.Version : 'web',
    'entity-name': 'my',
  },
});

export async function registerDevice(fcm_token: string | void | undefined) {
  if (!fcm_token) return console.log('NO_TOKEN');
  return crmApi.fcm.register({token: fcm_token, platform: Platform.OS});
}

export async function resetPassword(email: string) {
  const base = Platform.OS === 'web' ? location.origin + '/' : 'amana://';
  return crmApi.auth.sendMagicLink({email, redirect_uri: base + 'public/forgotpassword'});
}
