import {api} from '../../api';
import {makeAutoObservable} from 'mobx';

export class AccountBalance {
  private external_id: string;
  private platform: 'mt4' | 'mt5';

  constructor(external_id: string, platform: 'mt4' | 'mt5') {
    makeAutoObservable(this);
    this.external_id = external_id;
    this.platform = platform;
  }

  getBalanceDetails() {
    return api.get(`/api/snapshot/${this.platform}/${this.external_id}`).then(r => {
      const res = r?.data;
      if (!res) return null;
      return {
        freeMargin: res.m_free,
        equity: res.eq,
      };
    });
  }
}
