export enum LanguageCodesNames {
  'aa' = 'Afar',
  'ab' = 'Abkhazian',
  'af' = 'Afrikaans',
  'ak' = 'Akan',
  'am' = 'Amharic',
  'ar' = 'Arabic',
  'as' = 'Assamese',
  'aw' = 'Awadhi',
  'ay' = 'Aymara',
  'az' = 'Azerbaijani',
  'b1' = 'Bhojpuri',
  'b2' = 'Maithili',
  'ba' = 'Bashkir',
  'be' = 'Belarussian',
  'bg' = 'Bulgarian',
  'bh' = 'Bihari',
  'bi' = 'Bislama',
  'bl' = 'Balochi',
  'bn' = 'Bengali',
  'bo' = 'Tibetan',
  'br' = 'Breton',
  'ca' = 'Catalan',
  'cb' = 'Cebuano',
  'ce' = 'Chechen',
  'co' = 'Corsican',
  'cs' = 'Czech',
  'cy' = 'Welsh',
  'da' = 'Danish',
  'de' = 'German',
  'dk' = 'Dakhini',
  'dz' = 'Bhutani',
  'el' = 'Greek',
  'en' = 'English',
  'eo' = 'Esperanto',
  'es' = 'Spanish',
  'et' = 'Estonian',
  'eu' = 'Basque',
  'fa' = 'Persian',
  'fi' = 'Finnish',
  'fj' = 'Fiji',
  'fo' = 'Faeroese',
  'fr' = 'French',
  'fy' = 'Frisian',
  'ga' = 'Irish',
  'gd' = 'ScottishGaelic',
  'gl' = 'Galician',
  'gn' = 'Guarani',
  'gu' = 'Gujarati',
  'ha' = 'Hausa',
  'hi' = 'Hindi',
  'hr' = 'Croatian',
  'ht' = 'HaitianCreole',
  'hu' = 'Hungarian',
  'hy' = 'Armenian',
  'ia' = 'Interlingua',
  'ie' = 'Interlingue',
  'ik' = 'Inupiak',
  'in' = 'Indonesian',
  'is' = 'Icelandic',
  'it' = 'Italian',
  'iw' = 'Hebrew',
  'ja' = 'Japanese',
  'ji' = 'Yiddish',
  'jw' = 'Javanese',
  'ka' = 'Georgian',
  'kb' = 'Kabyle',
  'ki' = 'Konkani',
  'kk' = 'Kazakh',
  'kl' = 'Greenlandic',
  'km' = 'Khmer',
  'kn' = 'Kannada',
  'ko' = 'Korean',
  'ks' = 'Kashmiri',
  'ku' = 'Kurdish',
  'ky' = 'Kirghiz',
  'la' = 'Latin',
  'lb' = 'Luxembourgish',
  'lm' = 'Lombard',
  'ln' = 'Lingala',
  'lo' = 'Laothian',
  'lt' = 'Lithuanian',
  'lv' = 'Latvian',
  'mg' = 'Malagasy',
  'mi' = 'Maori',
  'mk' = 'Macedonian',
  'ml' = 'Malayalam',
  'mn' = 'Mongolian',
  'mo' = 'Moldavian',
  'mr' = 'Marathi',
  'ms' = 'Malay',
  'mt' = 'Maltese',
  'mu' = 'Makhuwa',
  'mw' = 'Marwari',
  'my' = 'Burmese',
  'na' = 'Nauru',
  'ne' = 'Nepali',
  'nl' = 'Dutch',
  'no' = 'Norwegian',
  'oc' = 'Occitan',
  'om' = 'Oromo',
  'or' = 'Oriya',
  'pa' = 'Punjabi',
  'pl' = 'Polish',
  'ps' = 'Pashto',
  'pt' = 'Portuguese',
  'qu' = 'Quechua',
  'ri' = 'Rifian',
  'rm' = 'RhaetoRomance',
  'rn' = 'Kirundi',
  'ro' = 'Romanian',
  'ru' = 'Russian',
  'rw' = 'Kinyarwanda',
  'sa' = 'Sanskrit',
  'sd' = 'Sindhi',
  'sg' = 'Sangro',
  'sh' = 'SerboCroatian',
  'si' = 'Sinhalese',
  'sk' = 'Slovak',
  'sl' = 'Slovenian',
  'sm' = 'Samoan',
  'sn' = 'Shona',
  'so' = 'Somali',
  'sq' = 'Albanian',
  'sr' = 'Serbian',
  'ss' = 'Siswati',
  'st' = 'Sesotho',
  'su' = 'Sundanese',
  'sv' = 'Swedish',
  'sw' = 'Swahili',
  'ta' = 'Tamil',
  'te' = 'Telugu',
  'tg' = 'Tajik',
  'th' = 'Thai',
  'ti' = 'Tigrinya',
  'tk' = 'Turkmen',
  'tl' = 'Tagalog',
  'tm' = 'Tuareg',
  'tn' = 'Setswana',
  'to' = 'Tonga',
  'tr' = 'Turkish',
  'ts' = 'Tsonga',
  'tt' = 'Tatar',
  'tw' = 'Twi',
  'tz' = 'Tamazight',
  'ug' = 'Uyghur',
  'uk' = 'Ukrainian',
  'ur' = 'Urdu',
  'uz' = 'Uzbek',
  'vi' = 'Vietnamese',
  'vo' = 'Volapuk',
  'wo' = 'Wolof',
  'xh' = 'Xhosa',
  'yo' = 'Yoruba',
  'zh' = 'Chinese',
  'zu' = 'Zulu',
}

export type ILanguageCode = keyof typeof LanguageCodesNames;
