import {crmApi} from '@/Lib/api';
import {useQuery} from '@tanstack/react-query';

export function useCountries() {
  const query = useQuery({
    staleTime: Infinity,
    queryKey: ['countries'],
    queryFn: () => crmApi.countries.getCountries().then(({data}) => data),
  });

  return {query};
}
