import {crmApi} from '../api';
import {GetCountriesData} from '../api/crm/Api';
import {makeAutoObservable} from 'mobx';

class CountryStore {
  countryList: GetCountriesData = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    this.isLoading = true;
    crmApi.countries
      .getCountries()
      .then(({data}) => {
        this.countryList = data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}
export const countryStore = new CountryStore();
