import {crmApi} from '../api';
import {GetMyDocumentsData} from '../api/crm/Api';
import {ArrayElement} from 'types';
import {makeAutoObservable} from 'mobx';

export class Document {
  category: string;
  auto_approved: boolean;
  approved_at: string | null;
  expire_at: string | null;
  rejectedAt: string | null;
  type: string;
  created_at: Date;

  constructor(data: ArrayElement<GetMyDocumentsData>) {
    this.category = data.category;
    this.auto_approved = data.auto_approved;
    this.approved_at = data.approved_at;
    this.expire_at = data.expire_at;
    this.rejectedAt = data.rejectedAt;
    this.type = data.type;
    this.created_at = new Date(data.createdAt);

    makeAutoObservable(this);
  }

  get isVerified() {
    return (this.auto_approved || this.approved_at !== null) && this.rejectedAt === null;
  }

  get isRejected() {
    return this.rejectedAt !== null;
  }

  get isSubmitted() {
    return !this.auto_approved && this.approved_at === null && this.rejectedAt === null;
  }

  get isExpired() {
    return this.expire_at && new Date(this.expire_at) < new Date();
  }
}
class DocumentStore {
  private _documents = [] as Document[];

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    crmApi.client.getMyDocuments().then(({data}) => {
      this._documents = data.map(d => new Document(d));
    });
  };

  get idDocuments() {
    return this._documents.filter(d => d.category === 'ID');
  }

  get porDocuments() {
    return this._documents.filter(d => d.category === 'POR');
  }

  get isIdentityVerified() {
    if (!this.idDocuments.length) return false;
    return this.idDocuments?.every(d => d.isVerified);
  }

  get isIdentityRejected() {
    if (!this.idDocuments.length) return false;
    return this.idDocuments?.every(d => d.isRejected);
  }

  get isIdentitySubmitted() {
    if (!this.idDocuments.length) return false;
    return this.idDocuments?.every(d => d.isSubmitted);
  }

  get isIdentityExpired() {
    if (!this.idDocuments.length) return false;
    return this.idDocuments?.every(d => (d.isVerified ? d.isExpired : false));
  }

  get isPorVerified() {
    return this.porDocuments?.findIndex(d => d.isVerified) > -1;
  }

  get isPorRejected() {
    if (!this.porDocuments.length) return false;
    return this.porDocuments?.every(d => d.isRejected);
  }

  get isPorSubmitted() {
    if (!this.porDocuments.length) return false;
    return this.porDocuments?.every(d => d.isSubmitted);
  }

  get isPorExpired() {
    if (!this.porDocuments.length) return false;
    return this.porDocuments?.every(d => (d.isVerified ? d.isExpired : false));
  }
}

export const documentStore = new DocumentStore();
