export * from './const';
export * from './languageCodesNames';
export * from './userStatuses';
export * from './reasons';
export * from './states';

export const mt_type = {
  MT4: 'MT4',
  MOBILE: 'MOBILE',
  MT5_HEDGED: 'MT5_HEDGED',
  MT5_NETTING: 'MT5_NETTING',
} as const;

export const mt_account_name = {
  [mt_type.MT4]: 'MT4',
  [mt_type.MT5_HEDGED]: 'MT5 (Hedged)',
  [mt_type.MT5_NETTING]: 'MT5 (Netting)',
  [mt_type.MOBILE]: 'amana',
};

// export const TradingAccountPlatform: Record<MT_TYPE, Platforms> = {
//   MT4: 'MT4',
//   MOBILE: 'MT5',
//   MT5_HEDGED: 'MT5',
//   MT5_NETTING: 'MT5',
// };

export const accountName = {
  [mt_type.MT4]: 'MT4',
  [mt_type.MT5_HEDGED]: 'MT5 (Hedged)',
  [mt_type.MT5_NETTING]: 'MT5 (Netting)',
  [mt_type.MOBILE]: 'amana',
};
