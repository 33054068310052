import {appUiStore} from '../stores/AppUi';
import {sessionStore} from '../stores/Session';
import DeviceEventEmitter from '../DeviceEmitter';
import {settingsStore} from '../stores/SettingsStore';
import {sleep} from '@/Lib';
import {DIST, VERSION} from '@/Env';
import {AxiosError, AxiosInstance} from 'axios';
import {runInAction} from 'mobx';
import {Platform} from 'react-native';

type InterceptorError = AxiosError & {config: {__isRetryRequest?: any}};
export const inject401Interceptor = async (_api: AxiosInstance) => {
  const responseId = _api.interceptors.response.use(
    res => {
      const TZ = Number(res.headers['x-tz-offset']);
      if (TZ && TZ !== appUiStore.TZ) {
        runInAction(() => {
          appUiStore.TZ = Number(res.headers['x-tz-offset']);
        });
      }

      return res;
    },
    async (error: InterceptorError) => {
      const response = error.response;
      if (!response) {
        console.warn('No response came from http call ! are u offline ?');

        return Promise.reject(error);
      }

      if (response.status === 400) {
      } else if (response && response.status > 400) {
        console.error(`
        =========================================================================================  
        HTTP ERROR:
        URL: ${String(error?.config?.baseURL) + error?.config?.url}
        METHOD: ${JSON.stringify(error.config.method)}
        STATUS: ${response?.status}
        response: ${JSON.stringify(response?.data)}
        params: ${JSON.stringify(error.config.params)}
        headers: ${JSON.stringify(error.config.headers)}
        =========================================================================================
      `);
      } else {
        console.warn(
          `
        =========================================================================================
        HTTP WARNING:
        URL: ${String(error?.config?.baseURL) + error?.config?.url}
        METHOD: ${JSON.stringify(error.config.method)}
        STATUS: ${response?.status}
        params: ${JSON.stringify(error.config.params)}
        response: ${JSON.stringify(response?.data)}
        =========================================================================================
      `,
          error
        );
      }

      if (response) {
        if (response.status === 401 || response.status === 403) {
          console.warn('token expired, refreshing...', sessionStore.ttl);
          await sessionStore.renewAccessToken();
          const auth = await sessionStore.access_token;
          if (auth) {
            error.config.headers = error.config.headers || {};
            error.config.headers.Authorization = `Bearer ${auth}`;
            error.config.__isRetryRequest = (error.config.__isRetryRequest || 0) + 1;

            console.log('Retrying request with new token', sessionStore.ttl, error.config.headers);
            return sleep(Math.max(10000, error.config.__isRetryRequest * 500)).then(() =>
              _api.request(error.config)
            );
          }

          DeviceEventEmitter.emitEvent('LOGOUT', {reason: '401'});
        }
      }

      return Promise.reject(error);
    }
  );

  const tokenInjectorId = _api.interceptors.request.use(async config => {
    const authToken = await sessionStore.access_token;
    console.log('Sending request to:' + config.url, !!authToken, sessionStore.ttl);
    if (authToken) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${authToken}`;
      config.headers['x-login'] = sessionStore.account?.login || '';
      config.headers['Accept-Language'] = settingsStore.lang || 'en';
      config.headers['x-platform'] = Platform.OS;
      if (Platform.Version) config.headers['x-platform-version'] = Platform.Version;
      config.headers['x-app-version'] = VERSION;
      config.headers['x-version'] = DIST;
    }

    return config;
  });

  return Promise.resolve(() => {
    _api.interceptors.response.eject(responseId);
    _api.interceptors.request.eject(tokenInjectorId);
  });
};
