import {AccountBalance} from './entities/AccountBalance';

class AccountBalanceStore {
  private accounts = new Map<string, AccountBalance>();

  get Accounts() {
    return this.accounts;
  }
  getAccountBalance = (external_id: string) => {
    return this.accounts.get(external_id)?.getBalanceDetails();
  };

  addAccount = (external_id: string, platform: 'mt4' | 'mt5') => {
    if (!this.accounts.has(external_id)) {
      this.accounts.set(external_id, new AccountBalance(external_id, platform));
    }
  };

  isAnyAccountFund = async () => {
    const balanceRequests = Array.from(this.accounts.values()).map(acc => acc.getBalanceDetails());
    const accountBalances = await Promise.all(balanceRequests);
    return accountBalances.some(acc => acc?.equity > 0);
  };
}
export const accountBalanceStore = new AccountBalanceStore();
