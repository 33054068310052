import {KYCForm} from './Form';
import {crmApi} from '@/Lib';
import {GetAllDefinitionsData, GetKycFormByIdData, GetOrCreateKycFormData} from '@/Lib/api/crm/Api';
import {makeAutoObservable, runInAction} from 'mobx';

export class KycStore {
  visible = true;
  page = 0;
  screen = 0;
  form: KYCForm | null = null;
  userKycDetails = null as GetOrCreateKycFormData | null;
  definitions = null as GetAllDefinitionsData | null;
  kycFormDetails = null as GetKycFormByIdData | null;

  fetchKYCDefinitions = () =>
    crmApi.client.getAllDefinitions().then(res => {
      runInAction(() => {
        this.definitions = res.data;
      });
    });

  fetchKYCFormDetails = (name: string) =>
    crmApi.client.getKycFormById(name).then(res => {
      runInAction(() => {
        this.kycFormDetails = res.data;
      });
    });

  async fetchUserKYCDetails() {
    return crmApi.client
      .getOrCreateKycForm({
        entity: 'my',
        form_name: 'individual',
      })
      .then(res => {
        runInAction(() => {
          this.userKycDetails = res.data;
          if (res.data.page) this.page = res.data.page;
          // userStore.updateUserInfoFromKyc(res.data.answers);
        });
        return res.data;
      });
  }

  fetch = async () => {
    const userKycForm = await this.fetchUserKYCDetails();
    await this.fetchKYCFormDetails(userKycForm.formType);
    await this.fetchKYCDefinitions();
  };

  setScreen(screen: number) {
    this.screen = screen;
  }

  setPage(page: number) {
    this.page = page;
  }
  dispose = () => {
    this.form = null;
    this.visible = true;
    this.page = 0;
  };
  dismiss = () => {
    this.visible = false;
  };
  openKyc = () => {
    this.visible = true;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const kycStore = new KycStore();
