import {appUiStore} from './AppUi';
import {accountBalanceStore} from './AccountBalanceStore';
import {sessionStore} from './Session';
import {userStore} from './UserStore';
import {countryStore} from './CountryStore';
import {api, crmApi} from '../api';
import {USER_STATUSES} from '../const';
import {checkIfMT} from '../helpers';
import {MT_TYPE, TradingAccount} from '../types';
import {AccountTypes} from '../const/accountTypes';
import {kycStore} from '@/Features/PublicApp/screens/onboarding/crm/KYCStore';
import {makeAutoObservable, runInAction} from 'mobx';

import type {Tapi} from '../websocket';

export class AccountStore {
  currency = 'USD';
  Balance = 0;
  Credit = '';
  MarginFree = '';
  Profit = '';
  Storage = '';
  Equity = '';
  Leverage = '';
  EquityPrevDay = '';
  // ----- all attributes bellow are not used on ui
  Group = '';
  get hasCrypto() {
    return String(this.Group).indexOf('NoCRY') === -1;
  }
  Registration = '';
  TradingAccounts: TradingAccount[] | null = null;
  creatingAccount = false;

  get selectedTradingAccount() {
    return this.TradingAccounts?.find(t => t.external_id === sessionStore.account?.login);
  }

  // TODO - check all statuses
  get AccountState() {
    const {userKycDetails} = kycStore;
    const {userDetails} = userStore;
    const idStatus = userKycDetails?.id_status?.toLowerCase();
    const porStatus = userKycDetails?.por_status?.toLowerCase();

    if (!userDetails || !userKycDetails) return null;

    if (!userKycDetails?.answers || !Object.keys(userKycDetails?.answers).length) {
      return USER_STATUSES.NEW;
    }
    const phoneVerified = userDetails?.phone_verified !== null;

    const kycComplete = userKycDetails?.isValid;

    if (!kycComplete) return USER_STATUSES.INCOMPLETE;

    if (!idStatus) return USER_STATUSES['Pending IDWISE'];
    if (idStatus === 'rejected') return USER_STATUSES.NODOCS;
    if (idStatus === 'refer' || idStatus === 'pending') return USER_STATUSES.PENDING;

    const approved = phoneVerified && idStatus === 'approved';

    const level1Approved = approved && porStatus !== 'approved';
    const pendingPor = approved && porStatus === 'pending';

    if (level1Approved) return USER_STATUSES['LEVEL-1 Approved'];
    if (pendingPor) return USER_STATUSES.PENDINGKYCREVIEW;

    const level2Approved = approved && porStatus === 'approved';
    if (level2Approved) return USER_STATUSES.ACTIVE;

    return USER_STATUSES.PENDING;
  }

  clear = () => {
    Object.keys(this).forEach(k => {
      const type = typeof this[k as keyof AccountStore];
      if ((k in this && type === 'string') || type === 'number') {
        // @ts-ignore
        this[k] = '';
      }
    });
    this.TradingAccounts = null;
  };

  refresh = async () => {};

  get isLimited() {
    return this.AccountState?.name !== ('ACTIVE' as any);
  }

  get isFundingBlocked() {
    return (
      ['LEVEL-1 Approved', 'PENDINGKYCREVIEW', 'ACTIVE'].includes(
        this?.AccountState?.name || ''
      ) === false
    );
  }

  constructor() {
    makeAutoObservable(this, {
      refresh: false,
      update: false,
      fetchAndUpdateMetrics: false,
    });
  }

  async loadAccounts() {
    return new Promise<TradingAccount[]>(async resolve => {
      this.TradingAccounts = await this.fetchTradingAccounts()
        .then(tradingAccounts =>
          tradingAccounts.map(t => {
            const type = (t.metadata?.type as MT_TYPE) || 'MOBILE';
            return {
              ...t,
              type,
            };
          })
        )
        .then(res => {
          res.forEach(t => {
            const platform = t?.type === 'MT4' ? 'mt4' : 'mt5';
            accountBalanceStore.addAccount(t.external_id, platform);
          });
          return res;
        })
        .catch(e => {
          console.error('Error fetching trading accounts', e);
          return [];
        });
      resolve(this.TradingAccounts);
    });
  }

  async fetchAndUpdateMetrics() {
    api.get('/api/metrics').then(res => {
      this.update(res.data);
    });
  }

  fetchTradingAccounts = () => crmApi.client.getUserTradingAccounts().then(res => res.data);

  get isMT(): boolean {
    if (!this.selectedTradingAccount) {
      return (
        !!userStore.userDetails?.metadata?.mt_preference &&
        userStore.userDetails?.metadata?.mt_preference !== 'MOBILE'
      );
    }

    return checkIfMT(this.selectedTradingAccount);
  }

  update = (d: Tapi.Metrics | Tapi.User) => {
    runInAction(() => {
      Object.assign(this, d);
    });
  };

  selectTradingAccount = async (account_id: string) => {
    const account = this.TradingAccounts?.find(t => t.id === account_id);

    if (!account) return;

    // if (this.isMT) priceEngine.dispose();
    // else priceEngine.setup();

    appUiStore.setFullScreenLoader(true);
    await new Promise(async resolve => {
      const tokens = await crmApi.auth
        .refreshToken({
          refresh_token: sessionStore.refresh_token,
          account_id,
        })
        .then(r => r.data);

      sessionStore.onRefresh(tokens);
      resolve(account);
    }).finally(() => {
      appUiStore.setFullScreenLoader(false);
    });
  };

  get SuggestedAccountType() {
    let accountTypeName: string = '';
    const countries = countryStore.countryList;
    const nationality = kycStore?.userKycDetails?.nationality;
    const residency = kycStore?.userKycDetails?.residency;

    if (!countries || !nationality || !residency) return '';

    if (nationality === 'UZB' || residency === 'UZB') {
      accountTypeName = 'MT5AMANA_UZBEK';
    } else {
      let isDW = countries.some(c => c.code3 === nationality && c.active);
      if (!isDW) isDW = countries.some(c => c.code3 === residency && c.expatsAllowed && c.active);

      let isCrypto = residency !== 'QAT';

      accountTypeName = AccountTypes.find(
        accountType => accountType?.is_crypto === isCrypto && accountType?.is_dw === isDW
      )?.name as MT_TYPE;

      accountTypeName = accountTypeName || 'MT5AMANA_NO_DW';
    }

    return accountTypeName;
  }

  createTradingAccount = async (type: MT_TYPE) => {
    if (!kycStore.userKycDetails) return false;

    let accountTypeName: string = type;

    if (type === 'MOBILE') {
      accountTypeName = this.SuggestedAccountType;
    }

    return crmApi.client
      .createFromKycForm({
        accountTypeName,
        kycFormId: kycStore.userKycDetails.id,
        isDemo: false,
        metadata: {
          type,
        },
      })
      .then(_ => {
        this.loadAccounts();
        return true;
      });
  };
}

export const accountStore = new AccountStore();
