import {logout} from './rootStore';
import {crmApi} from '../api';
import {GetMeData} from '../api/crm/Api';
import {makeAutoObservable, runInAction} from 'mobx';

class UserStore {
  hasValidForm = false;

  userDetails = {} as GetMeData;

  constructor() {
    makeAutoObservable(this);
  }

  get isBlockedAccount() {
    return this.userDetails?.metadata?.lead_method?.toLowerCase() === 'black';
  }

  fetch() {
    return crmApi.client
      .getMe()
      .then(({data}) => {
        runInAction(() => {
          this.userDetails = data;
          console.log(data);
        });
        // const form = data.user_kyc_form[0];
        // this.updateUserInfoFromKyc(form?.answers);
        return data;
      })
      .catch(e => {
        console.log('error getMe', e);
        logout();
      });
  }

  async updateUserInfoFromKyc(kycFields?: Record<string, string>) {
    if (!kycFields) return;

    const extractFields = ['dob', 'fname', 'lname', 'phone'];
    const info = extractFields.reduce((acc, field) => {
      // @ts-ignore
      if (field in this && kycFields[field] !== undefined) acc[field] = kycFields[field];
      return acc;
    }, {});

    runInAction(() => {
      Object.assign(this, info);
    });
  }
}

export const userStore = new UserStore();
