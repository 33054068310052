export const REASONS = [
  ['CLIENT', 'Order placed by a client manually through the client terminal.'],
  ['EXPERT', 'Order placed by a client with using an Expert Advisor.'],
  ['DEALER', 'An order placed by a client from a Web terminal.'],
  ['SL', 'Order placed as a result of Stop Loss activation.'],
  ['TP', 'Order placed as a result of Take Profit activation.'],
  ['SO', 'Order placed when the client reached the Stop-Out level.'],
  ['ROLLOVER', 'Order placed when reopening a position for charging swaps.'],
  ['EXTERNAL_CLIENT', 'Position opened from an external trading system.'],
  ['VMARGIN', 'Position reopened after charging the variation margin.'],
  ['GATEWAY', 'Position opened by a MetaTrader 5 gateway connected to the platform.'],
  [
    'SIGNAL',
    'Position opened as a result of copying a trading signal according to the subscription in the client terminal.',
  ],
  [
    'SETTLEMENT',
    'Position opened as a result of operations associated with a futures/option delivery date. It is currently not used.',
  ],
  [
    'TRANSFER',
    'Position opened as a result of transferring a position with a calculated price to a new symbol with the same underlying asset.',
  ],
  ['SYNC', 'Position opened while synchronizing a trading account state with an external system.'],
  [
    'EXTERNAL_SERVICE',
    'Position opened in the external trading system for service purposes (e.g. to correct a trading state).',
  ],
  [
    'MIGRATION',
    'Position opened as a result of import of clients trading operations from the MetaTrader 4 server.',
  ],
  ['MOBILE', 'Position opened via the MetaTrader 5 mobile terminal for Android or iPhone.'],
  ['WEB', 'Position opened via the web terminal.'],
  ['SPLIT', 'Position opened as a result of a symbol split.'],
] as const;
