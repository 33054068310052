export const AccountTypes = [
  {
    name: 'MT5AMANA_DW',
    group: 'demo\\MobileApp-Net-UAT',
    is_dw: true,
    is_crypto: true,
  },
  {
    name: 'MT5AMANA_NO_DW',
    group: 'demo\\MobileApp-Net-UAT',
    is_dw: false,
    is_crypto: true,
  },
  {
    name: 'MT5AMANA_DW_NO_CRYPTO',
    group: 'demo\\MobileApp-Net-UAT',
    is_dw: true,
    is_crypto: false,
  },
  {
    name: 'MT5AMANA_NO_DW_NO_CRYPTO',
    group: 'demo\\MobileApp-Net-UAT',
    is_dw: false,
    is_crypto: false,
  },
  {
    name: 'MT5AMANA_UZBEK',
    group: 'demo\\MobileApp-Net-UAT',
    is_dw: true,
    is_crypto: true,
  },
  {
    name: 'MT5_HEDGED',
    group: 'demo\\MobileApp-Net-UAT',
  },
  {
    name: 'MT5_NETTING',
    group: 'demo\\MobileApp-Net-UAT',
  },
  {
    name: 'MT4',
    group: 'demo\\MobileApp-Net-UAT',
  },
];
