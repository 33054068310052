import events from 'events';
const {EventEmitter} = events;

class DeviceEventEmitterInstance extends EventEmitter {
  emitEvent<T extends Events['type']>(type: T, payload: Extract<Events, {type: T}>['payload']) {
    return super.emit(type, payload);
  }
}

const DeviceEventEmitter = new DeviceEventEmitterInstance();
export default DeviceEventEmitter;

type Events = {type: 'LOGOUT'; payload: {reason: string; error?: any}};
